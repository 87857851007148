









































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import SearchBar from "@/components/search-bar.vue";
export default Vue.extend({
  props: {
    searchText: {
      type: String,
    },
  },
  components: {
    "search-bar": SearchBar
  },

  computed:{
    ...mapGetters("azActivityLogsModule", {
      activityLogs: "GET_ACTIVITY_LOGS"
    }),
    fields() {
      return [
        {
            key: 'user_name',
            label: this.$i18n.t('User Name'),
        },
        {
            key: "role",
            label: this.$i18n.t('Role'),
        },
        {
            key: "venue_owner_name",
            label: this.$i18n.t('Company'),
        },
        {
            key: "activity",
            label: this.$i18n.t('Activity'),
        },
        {
            key: "created_at",
            label: this.$i18n.t('Date'),
        },
      ]
    }
  },
  data() {
      return {
        currentPage: 1,
        perPage: 5,

      };
    },
  methods: {
    getDate(date) {
      const formattedDate = new Date(date).toISOString()
      return formattedDate.split('T')[0]
    },
    getRoleColor(role) {
        if(role.toLowerCase() === 'admin') {
            return 'admin'
        }
        if(role.toLowerCase() === 'accountant') {
            return 'accountant'
        }
        if(role.toLowerCase() === 'venue manager') {
            return 'manager'
        }
        if(role.toLowerCase() === 'marketer') {
            return 'marketer'
        }
        if(role.toLowerCase() === 'superadmin') {
            return 'superadmin'
        }
        return ''
    }
  },
});
