























import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import ActivityLogsTable from "@/components/activityLogs/ActivityLogsTable.vue"
import {mapGetters,mapActions} from 'vuex'
export default Vue.extend({
  components: {
    Layout,
    ActivityLogsTable
  },
  created(){
    this.fetchActivityLogs()
  },
  methods:{
    ...mapActions("azActivityLogsModule",{
      fetchActivityLogs:"FETCH_ACTIVITY_LOGS",
      exportLogs:"EXPORT_LOGS"
    }),
    getSearchValue(val){

      this.searchText=val
    }
  },
  data() {
    return {
      showModal: false,
      title: "Activity Logs",
      searchText:'',
    };
  },
});
